/*
 * 업무구분: 개인가입설계
 * 화 면 명: MSPPI180M
 * 화면설명: 개인가입설계 > 설계비교
 * 작 성 일: 0000.00.00
 * 작 성 자: 
 */
<template>
  <ur-page-container class="msp" title="설계비교" :show-title="true" type="subpage" :topButton="true">
    <!-- 고정영역 -->
    <template #frame-header-fixed>
      <ur-box-container alignV="start"  direction="column" class="msp-pi-wrap">
        <ur-box-container direction="column" class="pl24 mt30">
          <span class="contain-title fwb fs19rem">{{TrtyName}}</span>
          <ur-box-container direction="row" class="fexTy3 mb20 mt10">
            <div class="">
              <mo-badge class="badge-box lightblue h28" text="" shape="status">주피</mo-badge>
              <span class="contain-title fwn ml10 black">{{customerName}}</span><!-- 이름 -->
            </div>
            <mo-button class="h28 black" @click.stop="fn_OpenBasePlanInfo()">설계기본정보</mo-button>
          </ur-box-container>
        </ur-box-container>
        <ur-box-container alignH="between" alignV="center" componentid="" direction="row" class="h10px bgcolor-3"></ur-box-container>
        <ur-box-container direction="row" class="fexTy3 mt10 pl24 expand_bottomline pb10">
          <mo-button class="h28 blue" @click.stop="fn_OpenAddPlan()">비교할 설계추가</mo-button>
          <div class="dsInFlax">
            <span class="fs16rem">차이보기</span>
            <mo-switch v-model="compareModeFlag" small class="ns-switch ml10" @input="fn_ChangeCompareMode()" :disabled="compareModeDisabled"/>
          </div>
        </ur-box-container>
      </ur-box-container>
    </template>
    <!-- 고정영역 //-->

    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-pi-wrap pb50 bgcolor-1">
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-list bdnone">
        <mo-list :list-data="allEntplCurrList" expanded class="box--1">
          <template #list-item="{item}">
            <mo-list-item class="">
              <div class="list-item__contents">
                <div class="list-item__contents__title">
                  <span class="name fs17rem txtSkip2 mb4">{{item.title}}</span>
                </div>
                <div class="list-item__contents__info">
                  <div v-if="(item.Pmprd1 !== '')" class="list_content_date--first pal20">
                    <span class="mr10">
                      <mo-badge class="badge-box sm lightgreen" text="" shape="status">기준</mo-badge>
                    </span>
                    <span>{{item.Pmprd1}}</span>
                    <em></em>
                    <span>{{item.Pmprd2}}</span>
                    <div class="pl50 mt4">
                      <span class="fs16rem fwb crTy-bk1">{{item.CommazaEntAmt}}</span>
                      <em></em>
                      <span class="fs16rem fwb crTy-bk1">{{item.CommapremaftertaxAm}}</span>
                    </div>
                  </div>
                  <hr v-if="isCompare && (item.Pmprd1 !== '' && item._Pmprd1 !== '')" class="mla0">
                  <div v-if="(isCompare && item._Pmprd1 !== '')" class="list_content_date pal20">
                    <span class="mr10">
                      <mo-badge class="badge-box sm lightorange" text="" shape="status">비교</mo-badge> 
                    </span>
                    <span :class="{'crTy-orange2' : item._PmprdDiffFlag === true}">{{item._Pmprd1}}</span>
                    <em></em>
                    <span :class="{'crTy-orange2' : item._PmprdDiffFlag === true}">{{item._Pmprd2}}</span>
                    <div class="ml50 mt4 wauto fex-rw">
                      <div class="fex-clumn wauto">
                        <span class="fs16rem fwb crTy-bk1">{{item._CommazaEntAmt}}</span>
                        <span v-if="item._zaEntAmtGapVal > 0" class="fs14rem crTy-orange2 mt4">{{item._zaEntAmtGap}}</span>
                        <span v-else-if="item._zaEntAmtGapVal < 0" class="fs14rem crTy-blue2 mt4">{{item._zaEntAmtGap}}</span>
                      </div>                                    
                      <em class="mt6"></em>
                      <div class="fex-clumn wauto">
                        <span class="fs16rem fwb crTy-bk1">{{item._CommapremaftertaxAm}}</span>
                        <span v-if="item._zzmpadCnvlPAmGapVal > 0" class="fs14rem crTy-orange2 mt4">{{item._zzmpadCnvlPAmGap}}</span>
                        <span v-else-if="item._zzmpadCnvlPAmGapVal < 0" class="fs14rem crTy-blue2 mt4">{{item._zzmpadCnvlPAmGap}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mo-list-item>
          </template>
        </mo-list>
      </ur-box-container>

      <!-- 비교할 설계 추가 바텀시트 START -->
      <mo-bottom-sheet ref="nsbottomsheet" class="ns-bottom-sheet closebtn">
        <template v-slot:title>비교설계 추가</template>
        <div class="ns-btn-close" @click.stop="fn_CloseAddPlan()" name="닫기"></div>
        <!-- content 영역 -->
        <div class="content-area pl0 pr0 pb0">
          <ur-box-container v-if="cardList.length > 0" alignV="start" componentid="" direction="column" class="mb20">
            <mo-list :list-data="cardList">
              <template #list-item="{item}">
                <mo-list-item class="mo-list--check" :class="{'on' : item.chk === true}">
                  <div class="list-item__contents" @click.stop="addCompare(item)">
                    <div class="list-item__contents__info"> 
                      <div class="list_content_date pl24 pt10 pb10 fexTy3">
                        <div>
                          <span class="fs16rem">{{item.title}}</span>
                          <em class="em_normal"></em>
                          <span class="fs16rem">{{item.date}}</span>
                          <div class="mt4 wauto fex-rw">
                            <span class="fs17rem fwb">{{currencySymbol1}}{{item.prm}}{{currencySymbol2}}</span> 
                          </div>
                        </div>
                        <mo-icon icon="msp-check-bold"/>
                      </div>
                    </div>
                  </div>
                </mo-list-item>
              </template>
            </mo-list>
          </ur-box-container>

          <!-- no data -->
          <ur-box-container v-else alignV="start" componentid="ur_box_container_008" direction="column" class="ns-contract-list no-data">
            <mo-list-item>
              <div class="list-item__contents">
                <div class="list-item__contents__info hauto pt50 pb50">
                  <span>비교할 설계가 없습니다.</span>
                </div>
              </div>
            </mo-list-item>
            <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
              <div class="relative-div">
                <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click.stop="fn_NewAddPlan()">특약설계 바로가기</mo-button>
              </div>
            </ur-box-container>
          </ur-box-container>
        </div>
      </mo-bottom-sheet>
      <!-- /비교할 설계 추가 바텀시트 END-->

      <!-- 설계기본정보 바텀시트 START -->
      <mo-bottom-sheet ref="nsbottomsheet2" class="ns-bottom-sheet closebtn">
        <template v-slot:title>설계기본정보</template>
        <!-- content 영역 -->
        <div class="ns-btn-close" @click.stop="fn_CloseBasePlanInfo()" name="닫기"></div>
        <ur-box-container alignV="start" componentid="" direction="row" class="pl24 pb20">
          <div class="row-box--ty1">
            <mo-badge class="badge-box sm lightgreen wauto align-self-center" text="" shape="status">기준설계</mo-badge>
            <span class="mt6">{{leftPlanNm}}</span> <!-- 설계명 -->
            <span class="mt6 fs18rem">{{currencySymbol1}}<em class="em_normal--2 fs20rem fwb">{{leftpremaftertaxAmOfRef}}</em>{{currencySymbol2}}</span> <!-- 보험료 -->
            <ul class="box-info mt20">
              <li>{{leftpayfrqNm}}</li> <!-- 납입주기 -->
              <li>{{leftzzentplHlthsNm}}</li> <!-- 건강체 정보 -->
              <li>{{leftzzentplLineNm}}</li> <!-- 보험 종류 -->
            </ul>
            <mo-button class="h28 black mt20" @click.stop="PrepareMoveMSPPI001M('left')">가입설계</mo-button>
            <mo-button class="h28 black mt10" @click.stop="PrepareMoveMSPPI160M('left')">결과보기</mo-button>
          </div>

          <div class="row-box--ty1 ml10">
            <mo-badge class="badge-box sm lightorange wauto align-self-center" text="" shape="status">비교설계</mo-badge>
            <span class="mt6">{{rightPlanNm}}</span>
            <span class="mt6 fs18rem">{{currencySymbol1}}<em class="em_normal--2 fs20rem fwb">{{rightpremaftertaxAmOfRef}}</em>{{currencySymbol2}}</span>
            <ul class="box-info mt20">
              <li>{{rightpayfrqNm}}</li>
              <li>{{rightzzentplHlthsNm}}</li>
              <li>{{rightzzentplLineNm}}</li>
            </ul>
            <mo-button class="h28 black mt20" @click.stop="PrepareMoveMSPPI001M('right')">가입설계</mo-button>
            <mo-button class="h28 black mt10" @click.stop="PrepareMoveMSPPI160M('right')">결과보기</mo-button>
          </div>
        </ur-box-container>
      </mo-bottom-sheet>
      <!-- /설계기본정보 바텀시트 END -->

      <!--START: alert-기준 계약정보를 가져올 수 없습니다. -->
      <mo-bottom-sheet ref="refShowAlertMsg" class="ns-bottom-sheet ns-style3" noHeader>
        <div class="customer-info-txt txt-center">
          <p class="cancel-ment">{{isShowAlertMsg}}</p>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="ns-btn-relative">
            <div class="relative-div">
              <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round white" @click.stop="fn_ShowAlertOK()">확인</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>
      <!--END: alert-기준 계약정보를 가져올 수 없습니다. -->

      <!--START: alert-해당 상품은 설계비교가 불가능한 상품입니다. -->
      <mo-bottom-sheet ref="refPsbCompare" class="ns-bottom-sheet ns-style3" noHeader>
        <div class="customer-info-txt txt-center">
          <p class="cancel-ment">{{psbCompareMsg}}</p>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="ns-btn-relative">
            <div class="relative-div">
              <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round white" @click.stop="fn_PsbComparePopupClose()">확인</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>
      <!--END: alert-해당 상품은 설계비교가 불가능한 상품입니다. -->

    </ur-box-container> 
    <!-- Content 영역 end -->

  </ur-page-container>
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import Gaf from '@/ui/pi/common/Gaf'
import GafMainFunc from '@/ui/pi/common/GafMainFunc'
import piCommonUtil from '@/ui/pi/common/piCommonUtil'

export default {
  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPI180M',
  screenId: 'MSPPI180M',
  components: {},
  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props: {
    params: {}
  },
  /***********************************************************************************
  * 팝업일때 팝업 활성 형태 속성 
  * - 풀 팝업 형태 일때 mode 값 설정
  ***********************************************************************************/
  // modalProps: {
  //   mode: 'right' // 우->좌 open popup (defalut: 하->상)
  // },
  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {
      compareModeFlag: false, // 차이보기 on/off - 추가
      compareModeDisabled: true,
      customerType: '', // 고객타입(주피)
      customerName: '', // 고객이름
      allEntplOrgList: {
        LeftEntplList: [],
        RightEntplList: []
      }, // 특약목록 기준/비교 전체 리스트
      allEntplViewList: [], // 차이보기 비선택시 보여지는 특약 리스트
      allEntplDiffList: [], // 차이보기 선택시 보여지는 특약 리스트
      allEntplCurrList: [], // 현재 화면에 보여지는 특약 리스트
      prdtPosSellList: [], // 판매가능 상품 목록

      compareMode: 1, // 1: 선택한 특약 보기, 2: 차이점 모아보기
      title: '설계비교',
      parentParam: {}, // 이전 화면에서 넘겨 받은 param - 사용확인
      TrtyName: '', // 보험명
      leftPlanNm: '-', // 기준 설계 설계명 - title
      leftpremaftertaxAmOfRef: '-', // 기준 설계 실납입 보험료
      leftzzentplHlthsNm: '-', // 기준 설계 - 건강체 정보
      leftzzentplLineNm: '-', // 기준 설계 - 보험 종류
      rightPlanNm: '-', // 비교 설계 설계명 - title
      rightpremaftertaxAmOfRef: '-', // 비교 설계 실납입 보험료
      rightzzentplHlthsNm: '-', // 비교 설계 - 건강체 정보
      rightzzentplLineNm: '-', // 비교 설계 -보험 종류
      CustomerList: [], // 주피, 종피, 자녀1, 자녀2, 자녀3
      tempCustomerList: [], // 주피, 종피, 자녀1, 자녀2, 자녀3
      LeftEntplEntitys: [], // 왼쪽 가입설계 정보
      // LeftEntplList: [], // 기준 설계 가입설계 특약 목록 - 기분설계만 특약 목록을 가짐
      RightEntplEntitys: [], // 오른쪽 가입설계 정보
      LeftEntplOrgList: [], // 왼쪽 가입설계 특약 목록
      RightEntplOrgList: [], // 오른쪽 가입설계 특약 목록
      LeftDiffList: [], // 기준 설계 정보 - 차이점 모아보기 시 사용
      LeftAllList: [], // 기준 설계 보기 - 비교 설계와 같은 부분 다른부분 포함
      RightDiffList: [], // 비교 설계 정보 - 차이점 모아보기 시 사용
      RightAllList: [], // 기준 설계 보기 - 비교 설계와 같은 부분 다른부분 포함
      cardAllList: [], // 가입설계 전체 목록
      cardList: [
        // {title: '생활자금 지원', date: '2018-10-07', prm: '1,700,000'}
      ], // 비교 시 선택 된 부분 제외한 설계 목록
      leftResult: {}, // 기준 설계 정보
      rightResult: {}, // 비교 설계 정보
      requestPanel: 'none',
      // ismoveTSSPI101D: false,
      // ismoveMSPPI001M: false, // 가입설계 메인
      // ismoveTSSPI380M: false,
      // ismoveMSPPI160M: false, // 결과보기
      listOfInsrd: [], // 계약자 정보 - 주피 / 종피 / 자녀1 / 자녀2/ 자녀3
      isThere21: false,
      isThere23: false,
      isThere24_1: false,
      isThere24_2: false,
      isThere24_3: false,
      isShowAlertMsgFlag: false, //  - 사용확인
      isShowAlertMsg: '기준 계약정보를 가져올 수 없습니다.', //  - 사용확인
      returnData: [{
        key: '1',
        label: '선택한 특약보기'
      }],
      segmentData: [{
        key: '1',
        label: '선택한 특약보기'
      },
      {
        key: '2',
        label: '차이점 모아보기'
      }
      ],
      leftpayfrqNm: '-', // 기준 설계 보헙료
      rightpayfrqNm: '-', // 비교 설계 보헙료
      isCompare: false,
      isInit: false,
      scrollTop1: 0,
      isScroll1: false,
      scrollTop2: 0,
      isScroll2: false,
      flag: true,
      initFlag: false,
      initPlanId: '',
      // MoveTSSPI380MFlag: false,
      MoveMSPPI160MFlag: false, // 결과보기 화면
      guideText: '', // '비교의 기준이 되는 설계를 선택하세요.' 또는 '기준 설계와 비교할 설계를 선택하세요.'
      psbCompareFlag: false, //  - 사용확인
      psbCompareMsg: '해당 상품은 설계비교가 불가능한 상품입니다.', //  - 사용확인
      currencyId: '', // 외화보험 통화코드
      currencySymbol: '', // 통화기호,
      currencySymbol1: '', // 통화기호 $
      currencySymbol2: '' // 통화기호 원
    }
  },
  /***********************************************************************************
  * 컴포넌트 BASE 라우터 네비게이션 가드 함수 정의영역
  * - 화면전환 Route Life Cycle 함수
  * - 주로 next(fals) 진행불가처리 ,next()진행 처리 스크립트 컨트롤용도
  *   사용시 함수 주석 풀고 스크립트 작성 할것  
  ***********************************************************************************/
  // router에 의해 화면진입시 호출 인스턴스 생성되기전이라 this 자원 접근안됨  
  // beforeRouteEnter(to, from, next) { 반드시  next(fals) OR next()  호출 },
  // router에 의해 화면을 벗어날때 호출 
  // beforeRouteLeave(to, from, next) { 반드시  next(fals) OR next()  호출 },
  /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/
  // beforeCreate () {},
  created () {
    console.log('##################################### created () , this.$route.params : ',this.$route.params)
    if(this.$route.params && this.$route.params.zaPlanId) {
      if(this.$route.params.zaPlanId.trim() === '') {
        // 초기조회인데 설계아이디 없을때
        this.$router.push({name:'MSPPI310M'}) // 최근설계로 이동
        return
      }else {
        this.initFlag = true
        this.initPlanId = this.$route.params.zaPlanId
      }
    }else {
      this.$router.push({name:'MSPPI310M'}) // 최근설계로 이동
      return
    }
  },
  activated () {
    console.log('##################################### activated ()')
    // backButton event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_DeviceBackBtnHandler)
    // 하단 GNB 영역 안나오게
    this.$BottomManager.fn_SetBottomVisible(false)

    if(this.initFlag && this.initPlanId !== '') {
      // 최초로딩일때
      this.fn_Init()
      this.initFlag = false
      this.initPlanId = ''
    }else {
      if(this.MoveMSPPI160MFlag) {
        // 결과보기에서 돌아올때
        this.MoveMSPPI160MFlag = false
        return
      }
      if(this.$route.params && this.$route.params.zaPlanId && this.$route.params.zaPlanId !== '') {
        // 최초로딩은 되어있고 새로운 설계ID로 조회가 필요할때
        this.fn_Init()
        return
      }
    }
  },
  deactivated () {
    console.log('##################################### deactivated ()')
    if(this.MoveMSPPI160MFlag) {
    }else {
      this.parentParam.zaPlanId = ''
      this.cardList.splice(0, this.cardList.length)
      this.cardAllList.splice(0, this.cardAllList.length)
      this.isInit = this.isCompare = false
    }
    window.vue.getStoreMap().delete(this.$options.screenId) // Gaf stroe 제거
    Gaf.initStore('MSPPI001M')
    GafMainFunc.setStore('MSPPI001M')
    // backButton event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_DeviceBackBtnHandler)
  },
  // beforeMount() {},
  mounted () {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },
  // beforeUpdate() {},
  // updated() {},
  beforeDestroy () {
    window.vue.getStoreMap().delete(this.$options.screenId) // Gaf stroe 제거
    Gaf.initStore('MSPPI001M')
    GafMainFunc.setStore('MSPPI001M')
  },
  destroyed () {
    // backButton event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_DeviceBackBtnHandler)
  },
  /***********************************************************************************
  * watch 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
  *   (변수에 대한 값 변경시 반응형 콜백 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *       userNm : function() { this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {
    isShowAlertMsgFlag () {
      if (this.isShowAlertMsgFlag) {
        this.$refs.refShowAlertMsg.open()
      } else {
        this.$refs.refShowAlertMsg.close()
      }
    },
    psbCompareFlag () {
      if (this.psbCompareFlag) {
        this.$refs.refPsbCompare.open()
      } else {
        this.$refs.refPsbCompare.close()
      }
    }
  },
  /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { return this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {},
  /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/
  methods: {
    /******************************************************************************
    * Function명  : fn_OpenBasePlanInfo
    * 설명        : 설계기본정보 바텀시트 open
    ******************************************************************************/
    fn_OpenBasePlanInfo () {
      this.$refs.nsbottomsheet2.open()
    },
    /******************************************************************************
    * Function명  : fn_CloseBasePlanInfo
    * 설명        : 설계기본정보 바텀시트 close
    ******************************************************************************/
    fn_CloseBasePlanInfo () {
      this.$refs.nsbottomsheet2.close()
    },
    /******************************************************************************
    * Function명  : fn_OpenAddPlan
    * 설명        : 비교할 설계추가 바텀시트 open
    ******************************************************************************/
    fn_OpenAddPlan () {
      this.$refs.nsbottomsheet.open()
    },
    /******************************************************************************
    * Function명  : fn_CloseAddPlan
    * 설명        : 비교할 설계추가 바텀시트 close
    ******************************************************************************/
    fn_CloseAddPlan () {
      this.$refs.nsbottomsheet.close()
    },
    /******************************************************************************
    * Function명  : fn_NewAddPlan
    * 설명        : 비교할 설계 없을시 설계 추가
    ******************************************************************************/
    fn_NewAddPlan () {
      this.fn_CloseAddPlan()
      let pData = {}
      // pData.zaAgtFileId = this.leftResult.tmodel.rltnrInfo.filter((item) => {return item.zzcontvInsrdCd === '21'})[0].zaAgtFileId
      pData.zaPlanId = this.leftResult.pmodel.abdapolicy[0].zaPlanId
      let o = {}
      o.name = 'MSPPI001M'
      o.params = pData
      this.$router.push(o)
    },
    /******************************************************************************
    * Function명  : fn_PsbComparePopupClose
    * 설명        : 가설 비교 불가한 상품 알림 팝업 버튼 핸들러
    ******************************************************************************/
    fn_PsbComparePopupClose () {
      this.psbCompareFlag = false
    },
    /******************************************************************************
    * Function명  : fn_ChangeCompareMode
    * 설명        : 차이보기 토글 버튼 핸들러
    ******************************************************************************/
    fn_ChangeCompareMode () {
      let tmpArr = []
      if (this.compareModeFlag) {
        for (let i=0 ; i<this.allEntplDiffList.length ; i++) {
          tmpArr.push(this.allEntplDiffList[i])
        }
      } else {
        for (let i=0 ; i<this.allEntplViewList.length ; i++) {
          tmpArr.push(this.allEntplViewList[i])
        }
      }
      this.allEntplCurrList.splice(0, this.allEntplCurrList.length)
      this.allEntplCurrList = tmpArr
    },
    /******************************************************************************
    * Function명  : fn_DeviceBackBtnHandler
    * 설명        : 디바이스 Back Button 핸들러
    ******************************************************************************/
    fn_DeviceBackBtnHandler () {
      this.fn_Back()
    },
    /******************************************************************************
    * Function명  : fn_HeaderBackBtnHandler
    * 설명        : 상단 타이틀 백 아이콘 클릭 핸드러
    ******************************************************************************/
    fn_HeaderBackBtnHandler () {
      this.fn_Back()
    },
    /******************************************************************************
    * Function명 : fn_ShowAlertOK
    * 설명       : 기준 계약 정보가 없을 경우 팝업에서 뒤로 가도록 처리
    ******************************************************************************/
    fn_ShowAlertOK () {
      this.fn_Back()
    },
    /******************************************************************************
    * Function명 : fn_Back
    * 설명       : <- 뒤로 가기 버튼 처리
    ******************************************************************************/
    fn_Back () {
      // Gaf.initStore('MSPPI001M') // Gaf 초기화
      // GafMainFunc.setStore('MSPPI001M')
      window.vue.getStore('progress').dispatch('UPDATE', false)
      this.$router.go(-1)
    },
    /******************************************************************************
    * Function명 : fn_Init
    * 설명       : 최초 진입시 내부 변수들 초기화 수행
    ******************************************************************************/
    fn_Init () {
      window.vue.getStore('progress').dispatch('UPDATE', true)
      // 받아온 데이터 셋팅
      if (this.$route.params !== '') {
        this.parentParam = this.$route.params
      }
      // 초기 셋팅 - 1.오브젝트 초기화 , 2.상품 리스트 호출
      if (this.parentParam.zaPlanId && this.parentParam.zaPlanId !== '') {
        this.fn_initStdInfo() // 오브젝트 초기화
        this.getProductList() // 상품 리스트 호출
      } else {
        this.fn_Back() // 설계 아이디 없으면 뒤로 돌아간다
      }
    },
    /*******************************************************************************
     * 함수 : fn_initStdInfo ()
     * 설명 : 기준 설계를 위한 배열 생성 및 초기화
     *******************************************************************************/
    fn_initStdInfo () {
      let EntplEntity = {
        custInfo: {},
        EntplList: []
      }
      this.TrtyName = ''
      this.customerType = ''
      this.customerName = ''
      this.LeftEntplEntitys = []
      this.LeftAllList = []
      this.LeftEntplOrgList = []
      this.RightEntplEntitys = []
      this.RightAllList = []
      this.RightEntplOrgList = []

      for (let count = 0; count < 5; count++) {
        // left
        let _EntplEntity = _.cloneDeep(EntplEntity)
        this.LeftEntplEntitys.push(_EntplEntity)
        _EntplEntity = _.cloneDeep(EntplEntity)
        this.LeftAllList.push(_EntplEntity)
        // _EntplEntity = _.cloneDeep(EntplEntity)
        // this.LeftDiffList.push(_EntplEntity)
        _EntplEntity = _.cloneDeep(EntplEntity)
        this.LeftEntplOrgList.push(_EntplEntity)

        // right
        _EntplEntity = _.cloneDeep(EntplEntity)
        this.RightEntplEntitys.push(_EntplEntity)
        _EntplEntity = _.cloneDeep(EntplEntity)
        this.RightAllList.push(_EntplEntity)
        // _EntplEntity = _.cloneDeep(EntplEntity)
        // this.RightDiffList.push(_EntplEntity)
        _EntplEntity = _.cloneDeep(EntplEntity)
        this.RightEntplOrgList.push(_EntplEntity)
      }

      // 특약목록 기준/비교 전체 리스트
      this.allEntplOrgList = {}
      this.allEntplOrgList.LeftEntplList = []
      this.allEntplOrgList.RightEntplList = []
      // 차이보기 비선택시 보여지는 특약 리스트
      this.allEntplViewList = []
      // 차이보기 선택시 보여지는 특약 리스트
      this.allEntplDiffList = []
      // 현재 화면에 보여지는 특약 리스트
      this.allEntplCurrList = []
      // 판매가능 상품 목록
      this.prdtPosSellList = []

      this.rightPlanNm = '-' // 비교 설계 설계명 - title
      this.rightpremaftertaxAmOfRef = '-' // 비교 설계 실납입 보험료
      this.rightpayfrqNm = '-' // 비교 설계 보헙료
      this.rightzzentplHlthsNm = '-' // 비교 설계 - 건강체 정보
      this.rightzzentplLineNm = '-' // 비교 설계 - 보험 종류
      this.compareModeFlag = false // 차이보기 초기화
      this.compareModeDisabled = true
    },
    /******************************************************************************
    * Function명 : PrepareMoveMSPPI001M
    * 설명       : 현재 선택한 설계건의 상세 설계 화면 이동 시 전처리
    *              기준 설계 / 비교 설계 구분 처리
    ******************************************************************************/
    PrepareMoveMSPPI001M (pannel) {
      if (pannel === 'left') {
        if( this.LeftEntplEntitys.zaPlanId === undefined || this.LeftEntplEntitys.zaPlanId === '' ) {
          // 기준설계 아이디 없는 경우
          return
        }
        this.MoveMSPPI001M(this.LeftEntplEntitys.zaPlanId)
      }else {
        if( this.RightEntplEntitys.zaPlanId === undefined || this.RightEntplEntitys.zaPlanId === '' ) {
          // 비교설계 아이디 없는 경우
          return
        }
        this.MoveMSPPI001M(this.RightEntplEntitys.zaPlanId)
      }
      // 설계기본정보 바텀 시트 close
      this.fn_CloseBasePlanInfo()
    },
    /******************************************************************************
    * Function명 : MoveMSPPI001M
    * 설명       : 현재 선택한 설계건의 상세 설계 화면으로 이동
    *              현재 선택 한 설계의 PlanId를 전달
    ******************************************************************************/
    MoveMSPPI001M (zaPlanId) {
      this.$router.push({name: 'MSPPI001M', params: {zaPlanId: zaPlanId}})
    },
    /******************************************************************************
    * Function명 : PrepareMoveMSPPI160M
    * 설명       : 결과 보기 버튼 선택 시 전처리 수행
    ******************************************************************************/
    PrepareMoveMSPPI160M (pannel) {
      if(pannel === 'right' && !this.isCompare) {return} // 설계비교가 안되었을 경우
      this.MoveMSPPI160MFlag = true
      this.MoveMSPPI160M(pannel) // 페이지 이동
      this.fn_CloseBasePlanInfo() // 설계기본정보 바텀 시트 close
    },
    /******************************************************************************
    * Function명 : MoveMSPPI160M
    * 설명       : 현재 설계의 결과 보기 화면으로 이동
    ******************************************************************************/
    MoveMSPPI160M (pannel) {
      let tModel = {}
      let pModel = {}
      let vModel = []
      
      if (pannel === 'left') { // 왼쪽 설계의 결과 보기  처리
        tModel = _.cloneDeep(this.leftResult.tmodel)
        pModel = _.cloneDeep(this.leftResult.pmodel)
        for (let i = 0; i < this.LeftEntplOrgList.length; i++) {
          for (let j = 0; j < this.LeftEntplOrgList[i].EntplList.length; j++) {
            let divideUnit = this.LeftEntplOrgList[i].EntplList[j].unitNm === '만원' ? 10000 : 1000
            this.LeftEntplOrgList[i].EntplList[j].covInsAmt = this.LeftEntplOrgList[i].EntplList[j].zaEntAmt / divideUnit
            this.LeftEntplOrgList[i].EntplList[j].covPrm = this.LeftEntplOrgList[i].EntplList[j].premaftertaxAm
            vModel.push(_.cloneDeep(this.LeftEntplOrgList[i].EntplList[j]))
          }
        }
      } else { // 오른쪽 설계의 결과 보기  처리
        tModel = _.cloneDeep(this.rightResult.tmodel)
        pModel = _.cloneDeep(this.rightResult.pmodel)
        for (let i = 0; i < this.RightEntplOrgList.length; i++) {
          for (let j = 0; j < this.RightEntplOrgList[i].EntplList.length; j++) {
            let divideUnit = this.RightEntplOrgList[i].EntplList[j].unitNm === '만원' ? 10000 : 1000
            this.RightEntplOrgList[i].EntplList[j].covInsAmt = this.RightEntplOrgList[i].EntplList[j].zaEntAmt / divideUnit
            this.RightEntplOrgList[i].EntplList[j].covPrm = this.RightEntplOrgList[i].EntplList[j].premaftertaxAm
            vModel.push(_.cloneDeep(this.RightEntplOrgList[i].EntplList[j]))
          }
        }
      }

      let obj = {
        pmodel: pModel,
        tmodel: tModel,
        vmodel: vModel
      }
      this.MoveMSPPI160MFlag = true
      this.$router.push({name: 'MSPPI160M', params: obj})
    },
    /******************************************************************************
    * Function명 : getProductList
    * 설명       : 판매가능상품목록 조회 요청
    ******************************************************************************/
    getProductList () {
      let inputJson = {
        reprPrdtPgScCd: '01',
        stndYmd: '',
        tbtPlanYn: 'Y',
        orgNo01: this.getStore('userInfo').getters.getUserInfo.onpstHofOrgNo,
        orgNo02: this.getStore('userInfo').getters.getUserInfo.onpstDofNo,
        orgNo03: this.getStore('userInfo').getters.getUserInfo.onpstFofOrgNo
      }
      window.vue.getStore('progress').dispatch('FULL')
      piCommonUtil.invoke('URLPQL08166', inputJson, this.URLPQL08166_LocalCallBack, null, this.URLPQL08166_LocalCallBack, this, this)
    },
    /******************************************************************************
    * Function명 : URLPQL08166_LocalCallBack
    * 설명       : 판매가능상품목록 조회 응답 처리
    ******************************************************************************/
    URLPQL08166_LocalCallBack (result) {
      if (result === null || result === undefined || !piCommonUtil.isObj(result.pisalePrdtInfoVO)) {
        window.vue.getStore('progress').dispatch('UPDATE', false)
        return false
      } else {
        console.log('#### 판매가능상품목록 this.prdtPosSellList.length : ', this.prdtPosSellList.length)
        this.prdtPosSellList = result.pisalePrdtInfoVO
        this.fn_LoadInitData()
      }
    },
    /******************************************************************************
    * Function명 : fn_LoadInitData
    * 설명       : 화면 진입 시 초기화 수행
    *              최초 기준 설계 정보 요청 수행
    ******************************************************************************/
    fn_LoadInitData () {
      Gaf.initStore('MSPPI180M')
      GafMainFunc.setStore('MSPPI180M')
      Gaf.clearPanelList()
      Gaf.setProcessNo('1001')
      Gaf.addPanelList(this)
      // Gaf에 판매가능상품목록을 저장해준다.
      Gaf.setProductList(this.prdtPosSellList)

      // 화면 진입 시 기준설계 정보 요청으로 Panel 값은 left로 설정
      this.requestPanel = 'left'
      this.fn_requestGafData()
    },
    /******************************************************************************
    * Function명 : fn_requestGafData
    * 설명       : 가입설계에서 진입하여 현재 가설 정보를 불러 오는 것 - URLPQL00073 호출
    *              this.parentParam.zaPlanId 넘겨 받은 Param의 PlanId를 사용
    ******************************************************************************/
    fn_requestGafData () {
      if (this.parentParam.zaPlanId === '') {
        this.isShowAlertMsgFlag = true
        return
      }
      let inputJson = {'pmodel': {'abdapolicy': [{'gafProcessNo': Gaf.getProcessNo()}]},
        'tmodel': {
          'searchCriteria': {
            zaPlanId: this.parentParam.zaPlanId,
            applnrTt: '',
            rrnMaskingFlag: this.rrnMaskingFlag ? 'Y' : 'N'
          }
        }
      }
      Gaf.execute('MSPPI180M', 'URLPQL00073', inputJson, true, null, this)
    },
    /******************************************************************************
    * Function명 : fn_requestPlanByPlanId
    * 설명       : PlanId를 받아서 설계 정보 요청 처리
    ******************************************************************************/
    fn_requestPlanByPlanId (planid) {
      let inputJson = {'pmodel': {'abdapolicy': [{'gafProcessNo': Gaf.getProcessNo()}]},
        'tmodel': {
          'searchCriteria': {
            zaPlanId: planid, // planObj.planId,
            applnrTt: '', // this.applnrTt,
            rrnMaskingFlag: this.rrnMaskingFlag ? 'Y' : 'N'
          }
        }
      }
      Gaf.execute('MSPPI180M', 'URLPQL00073', inputJson, true, null, this)
    },
    /******************************************************************************
    * Function명 : URLPQL00073_LocalCallBack
    * 설명       : 요청한 설계 정보 처리
    ******************************************************************************/
    URLPQL00073_LocalCallBack (Response) {
      // 메모리 양을 줄이기 위해 Response의 ifbcAttrList 데이터 삭제
      // Response.tmodel.prodInfo.ifbcAttrList.splice(0, Response.tmodel.prodInfo.ifbcAttrList.length)

      // 실손보험의 경우 설계비교 불가 처리
      // "ZZCLNIC_I_T_A_ID" "zzclnicITACd" //의료실손상세속성코드RD 값 비교
      let zzclnicITACd = Response.pmodel.abdapolicy[0].abdapolpr[0].abdacovpac[0].abdacov[0].aldacov.zzclnicITACd
      if (zzclnicITACd !== '' && zzclnicITACd !== '00') {
        this.psbCompareFlag = true
        window.vue.getStore('progress').dispatch('UPDATE', false)
        return
      }

      switch (this.requestPanel) {
        case 'none':
          Gaf.setObj(Response)
          this.requestEtrlList()
          break
        case 'left': // 기준 설계 응답 처리
          this.guideText = '기준 설계와 비교할 설계를 선택하세요.'
          this.leftResult = null
          this.leftResult = Response
          this.fn_initLeftPaneleData()
          this.requestEtrlList()
          break
        case 'right': // 비교 설계 응답 처리
          this.rightResult = null
          this.rightResult = Response
          this.fn_initRightPaneleData()
          break
      }
      window.vue.getStore('progress').dispatch('UPDATE', false)
    },
    /******************************************************************************
    * Function명 : fn_zaPadCylCdToName
    * 설명       : 납입주기코드를 String으로 변환
    ******************************************************************************/
    fn_zaPadCylCdToName (zaPadCylCd) {
      let result = ''
      switch(zaPadCylCd) {
        case '00':
          result = '일시납'
          break
        case '12':
          result = '월납'
          break
      }
      return result
    },
    /******************************************************************************
    * Function명 : fn_getPlanName
    * 설명       : 설계 저장 명에서 설계 Title 정보 추출
    ******************************************************************************/
    fn_getPlanName (TrtyName, pPlanName) {
      let tempPlanName = pPlanName
      let planName = pPlanName.substring(TrtyName.length + 1, tempPlanName.length)
      return planName
    },
    /******************************************************************************
    * Function명 : fn_initLeftPaneleData
    * 설명       : 기준 설계 정보 변수 데이터 값 셋팅
    ******************************************************************************/
    fn_initLeftPaneleData () {
      let tModel = this.leftResult.tmodel
      let pModel = this.leftResult.pmodel

      this.TrtyName = pModel.abdapolicy[0].abdapolpr[0].ztpqlCboextPrcsAbdapolpr.zaPrdtNm // 상품명
      this.leftPlanNm = this.fn_getPlanName(this.TrtyName, pModel.abdapolicy[0].abdapolpr[0].ztpqlCboextPrcsAbdapolpr.zaPlanNm) // 설계명
      this.zaPadCylCd = this.fn_zaPadCylCdToName(tModel.baseContDto.zaPadCylCd) // 납입주기

      let coverageList = tModel.prodInfo.coverageList
      let baseInfoDto = tModel.prodInfo.baseInfoDto
      let baseContDto = tModel.baseContDto
      let abdapolicy = pModel.abdapolicy[0]
      this.leftzzentplHlthsNm = this.fn_getHealthValue(baseInfoDto, abdapolicy.abdapolpr[0].aldapolpr.zzentplHlthsCd)
      this.leftpayfrqNm = this.fn_getpayfrqNm(baseContDto, baseInfoDto)
      this.leftzzentplLineNm = this.fn_getzzentplLineNm(baseContDto, baseInfoDto)

      if (this.leftzzentplHlthsNm === '' || this.leftzzentplHlthsNm === undefined) {
        this.leftzzentplHlthsNm = '-'
      }
      if (this.leftpayfrqNm === '' || this.leftpayfrqNm === undefined) {
        this.leftpayfrqNm = '-'
      }
      if (this.leftzzentplLineNm === '' || this.leftzzentplLineNm === undefined) {
        this.leftzzentplLineNm = '-'
      }

      this.LeftEntplEntitys.zaPlanId = abdapolicy.abdapolpr[0].aldapolpr.zaPlanId

      // 변수 초기화
      this.isThere21 = false
      this.isThere23 = false
      this.isThere24_1 = false
      this.isThere24_2 = false
      this.isThere24_3 = false
      this.CustomerList = [null, null, null, null, null]
      let tmpName = ['주피', '종피', '자녀1', '자녀2', '자녀3']
      let tmpCode = ['21', '23', '24', '24', '24']
      for (let clidx = 0; clidx < 5; clidx++) {
        this.CustomerList[clidx] = {
          type: tmpName[clidx],
          code: tmpCode[clidx],
          name: ''
        }
      }

      // 202005 외화보험 통화키
      this.currencyId = this.$bizUtil.isEmpty(abdapolicy.currencyId) ? 'KRW' : abdapolicy.currencyId // 외화보험 통화코드
      if (this.currencyId === 'KRW') {
        this.currencySymbol = '원'
        this.currencySymbol1 = ''
        this.currencySymbol2 = '원'
      } else if(this.currencyId === 'USD') {
        this.currencySymbol = '$ '
        this.currencySymbol1 = '$ '
        this.currencySymbol2 = ''
      } else {
        this.currencySymbol = ''
        this.currencySymbol1 = ''
        this.currencySymbol2 = ''
      }

      // 202005 외화보험 기준설계
      this.leftpremaftertaxAmOfRef = this.$bizUtil.numberWithCommasCurr(pModel.abdapolicy[0].abdapolpr[0].polprAbdaprem[0].premaftertaxAm, this.currencyId, 'Y', 'N')

      // Pmodel에서 계약자 별 상품을 get하는 가장 큰 for
      let length = 1
      for ( let i = 0; i < length; i++ ) {
        let abdapolpr = abdapolicy.abdapolpr[i]
        let zzentplLineCd = abdapolpr.aldapolpr.zzentplLineCd

        // 계약자 별 계약 정보를 Get하는 두번째 for
        for ( let j = 0; j < abdapolpr.abdacovpac.length; j++ ) {
          let abdacovpac = abdapolpr.abdacovpac[j]
          let find_flag = false
          // Pmodel에서 특약 정보 하나 Get 처리
          for ( let k = 0; k < abdacovpac.abdacov.length; k++ ) {
            let abdacov = abdacovpac.abdacov[k]

            // tmodel의 coverageList에서 현재 특약 정보가 있는지 검색하는 for - 있으면 기준list에 입력 조건 충족
            for ( let l = 0; l < coverageList.length; l++ ) {
              if ( coverageList[l].insCode === abdacov.zzcoltrCd && coverageList[l].uiCode === abdacov.aldacov.zzentplRepCCd && ( coverageList[l].type === '' || coverageList[l].type === zzentplLineCd ) ) {
                // console.log('[L]동일한 코드를 찾음 ', l, ' - ', coverageList[l].insCode, ' - ', abdacov.zzcoltrCd )
                // console.log('[L]동일한 코드를 찾음 ', l, ' - ', coverageList[l].uiCode, ' - ', abdacov.aldacov.zzentplRepCCd )
                // console.log('[L]동일한 코드를 찾음 ', l, ' - ', coverageList[l].type, ' - ', zzentplLineCd )
                find_flag = true
                if ( find_flag === true ) {
                  find_flag = false
                  let covAbdaprem = abdacov.covAbdaprem[0] // 보험금 수집
                  let ztpqlCboextAddmAbdacov = abdacov.ztpqlCboextAddmAbdacov // title 수집
                  let aldacov = abdacov.aldacov
                  let covAbdasubjct = abdacov.covAbdasubjct[0]

                  // 202005 외화보험 가입금액
                  // let CommazaEntAmt = this.addMoneyCommas(GafMainFunc.setUnitAmt(ztpqlCboextAddmAbdacov.zaEntAmt, coverageList[l].unit))
                  // let _CommazaEntAmt = ztpqlCboextAddmAbdacov.zaEntAmt !== 0 ? this.addMoneyCommas(GafMainFunc.setUnitAmt(ztpqlCboextAddmAbdacov.zaEntAmt, coverageList[l].unit)) + coverageList[l].unitNm : ''
                  let _CommazaEntAmt = this.$bizUtil.numberWithCommasCurr(GafMainFunc.setUnitAmt(ztpqlCboextAddmAbdacov.zaEntAmt, coverageList[l].unit), this.currencyId, 'N', 'N')
                  _CommazaEntAmt = this.currencyId === 'KRW' ? _CommazaEntAmt + coverageList[l].unitNm + ' ' : this.currencySymbol + ' ' + _CommazaEntAmt

                  // 202005 외화보험 보험료
                  // let _CommapremaftertaxAm = covAbdaprem.premaftertaxAm !== '' ? this.addMoneyCommas(covAbdaprem.premaftertaxAm) + this.currencySymbol : ''
                  let _CommapremaftertaxAm = this.$bizUtil.numberWithCommasCurr(covAbdaprem.premaftertaxAm, this.currencyId, 'Y', 'Y', 3)

                  let _Pmprd_1 = GafMainFunc.makePrdText(aldacov.zzinprdTypCd, aldacov.zzinprdTypVl)
                  let _Pmprd_2 = GafMainFunc.makePrdText( covAbdaprem.covAldaprem.zzpmprdTypCd, covAbdaprem.covAldaprem.zzpmprdTypVl )
                  let _Pmprd = _Pmprd_1 + '/' + _Pmprd_2

                  let compareData = {
                    abdacov: abdacov, // 결과 보기에서 사용하기 위한 데이터
                    isChecked: true, // 결과 보기에서 사용하기 위한 데이터
                    isempty: false, // 비교 시 빈항목일 경우
                    zzcontvInsrdCd: abdacov.covAbdasubjct[0].zzcontvInsrdCd, // 피보험자 구분
                    zzcontvInsrdNm: this.fn_covertCodetoName(covAbdasubjct), // 피보험자 명 주피/종피/자녀1,2,3
                    insured: coverageList[l].insured, // 피보험자 코드
                    childNo: coverageList[l].childNo, // 자녀 구분코드
                    order: coverageList[l].order - '0', // 특약 순서
                    uiCode: coverageList[l].uiCode,
                    insCode: coverageList[l].insCode, // T_Model 특약 코드
                    zzcoltrCd: abdacov.zzcoltrCd, // P_Model 특약 코드
                    title: ztpqlCboextAddmAbdacov.zaInsrNm,
                    zaEntAmt: ztpqlCboextAddmAbdacov.zaEntAmt,
                    CommazaEntAmt: _CommazaEntAmt,
                    zaEntAmtGap: '', // 화면에 표시할 데이터
                    zaEntAmtGapVal: 0, // 연산 필요 시에 사용할 데이터
                    PmprdDiffFlag: false,
                    premaftertaxAm: covAbdaprem.premaftertaxAm,
                    CommapremaftertaxAm: _CommapremaftertaxAm, // 특약별 보험료 ( 화면 표시용 )
                    zzmpadCnvlPAmGap: '',
                    zzmpadCnvlPAmGapVal: 0,
                    zzstrkPrmAm: covAbdaprem.covAldaprem.zzstrkPrmAm, // 특약별 보험료( 오른쪽과 왼쪽 차이 계산용 )
                    zzstrkPrmAmGap: '',
                    unit: coverageList[l].unit,
                    unitNm: coverageList[l].unitNm,
                    Pmprd: _Pmprd,
                    Pmprd1: _Pmprd_1,
                    Pmprd2: _Pmprd_2,
                    zzinprdTypCd: aldacov.zzinprdTypCd
                  }
                  this.fn_checkCustomer(compareData, 'left')

                  if ( compareData.insured === '21' ) {
                    this.LeftEntplEntitys[0].EntplList.push(compareData)
                  }
                  if ( compareData.insured === '23' ) {
                    this.LeftEntplEntitys[1].EntplList.push(compareData)
                  }
                  if ( compareData.insured === '24' && compareData.childNo === '1' ) {
                    this.LeftEntplEntitys[2].EntplList.push(compareData)
                  }
                  if ( compareData.insured === '24' && compareData.childNo === '2' ) {
                    this.LeftEntplEntitys[3].EntplList.push(compareData)
                  }
                  if ( compareData.insured === '24' && compareData.childNo === '3' ) {
                    this.LeftEntplEntitys[4].EntplList.push(compareData)
                  }
                }
                break
              }
            }
          }
        }
      }

      this.LeftEntplOrgList.splice(0, this.LeftEntplOrgList.length)
      for ( let i = 0; i < this.LeftEntplEntitys.length; i++ ) {
        this.LeftEntplOrgList.push(this.LeftEntplEntitys[i])
      }

      // 주피보험자 변수에 담는처리
      this.customerType = this.CustomerList[0].type
      this.customerName = this.CustomerList[0].name
      // 초기화
      this.allEntplOrgList.LeftEntplList.splice(0, this.allEntplOrgList.LeftEntplList.length)
      this.allEntplViewList.splice(0, this.allEntplViewList.length)
      this.allEntplCurrList.splice(0, this.allEntplCurrList.length)
      // 주피보험자만 리스트에 담는다
      for (let i=0 ; i < this.LeftEntplEntitys[0].EntplList.length ; i++) {
        this.allEntplOrgList.LeftEntplList.push(this.LeftEntplEntitys[0].EntplList[i])
        this.allEntplViewList.push(this.LeftEntplEntitys[0].EntplList[i])
        this.allEntplCurrList.push(this.LeftEntplEntitys[0].EntplList[i])
      }
    },
    /******************************************************************************
    * Function명 : fn_initRightPaneleData
    * 설명       : 비교 설계 정보 변수 데이터 값 셋팅
    ******************************************************************************/
    fn_initRightPaneleData () {
      for (let count = 0; count < 5; count++) {
        let EntplEntity_r = {}
        EntplEntity_r = {
          custInfo: {},
          EntplList: []
        }
        this.RightEntplEntitys[count] = EntplEntity_r
        EntplEntity_r = {}
      }

      let tModel = this.rightResult.tmodel
      let pModel = this.rightResult.pmodel

      // 202005 외화보험 기준설계
      // this.rightpremaftertaxAmOfRef = this.addMoneyCommas(pModel.abdapolicy[0].abdapolpr[0].polprAbdaprem[0].premaftertaxAm)
      this.rightpremaftertaxAmOfRef = this.$bizUtil.numberWithCommasCurr(pModel.abdapolicy[0].abdapolpr[0].polprAbdaprem[0].premaftertaxAm, this.currencyId, 'Y', 'N')

      this.rightPlanNm = this.fn_getPlanName(this.TrtyName, pModel.abdapolicy[0].abdapolpr[0].ztpqlCboextPrcsAbdapolpr.zaPlanNm)
      this.zaPadCylCd = this.fn_zaPadCylCdToName(tModel.baseContDto.zaPadCylCd) // 납입주기

      let coverageList = tModel.prodInfo.coverageList
      let baseInfoDto = tModel.prodInfo.baseInfoDto
      let baseContDto = tModel.baseContDto
      let abdapolicy = pModel.abdapolicy[0]
      this.rightzzentplHlthsNm = this.fn_getHealthValue(baseInfoDto, abdapolicy.abdapolpr[0].aldapolpr.zzentplHlthsCd )
      this.rightpayfrqNm = this.fn_getpayfrqNm(baseContDto, baseInfoDto)
      this.rightzzentplLineNm = this.fn_getzzentplLineNm(baseContDto, baseInfoDto)

      if (this.rightzzentplHlthsNm === '' || this.rightzzentplHlthsNm === undefined) {
        this.rightzzentplHlthsNm = '-'
      }
      if (this.rightpayfrqNm === '' || this.rightpayfrqNm === undefined) {
        this.rightpayfrqNm = '-'
      }
      if (this.rightzzentplLineNm === '' || this.rightzzentplLineNm === undefined) {
        this.rightzzentplLineNm = '-'
      }

      this.RightEntplEntitys.zaPlanId = abdapolicy.abdapolpr[0].aldapolpr.zaPlanId

      // Pmodel에서 계약자 별 상품을 get하는 가장 큰 for
      for ( let i = 0; i < abdapolicy.abdapolpr.length; i++ ) {
        let abdapolpr = abdapolicy.abdapolpr[i]
        let zzentplLineCd = abdapolpr.aldapolpr.zzentplLineCd

        // 계약자 별 계약 정보를 Get하는 두번째 for
        for ( let j = 0; j < abdapolpr.abdacovpac.length; j++ ) {
          let abdacovpac = abdapolpr.abdacovpac[j]
          let find_flag = false
          // Pmodel에서 특약 정보 하나 Get 처리
          for ( let k = 0; k < abdacovpac.abdacov.length; k++ ) {
            let abdacov = abdacovpac.abdacov[k]

            // tmodel의 coverageList에서 현재 특약 정보가 있는지 검색하는 for - 있으면 기준list에 입력 조건 충족
            for ( let l = 0; l < coverageList.length; l++ ) {
              if ( coverageList[l].insCode === abdacov.zzcoltrCd && coverageList[l].uiCode === abdacov.aldacov.zzentplRepCCd && ( coverageList[l].type === '' || coverageList[l].type === zzentplLineCd ) ) {
                // console.log('[R]동일한 코드를 찾음 ', l, ' - ', coverageList[l].insCode, ' - ', abdacov.zzcoltrCd )
                // console.log('[R]동일한 코드를 찾음 ', l, ' - ', coverageList[l].uiCode, ' - ', abdacov.aldacov.zzentplRepCCd )
                // console.log('[R]동일한 코드를 찾음 ', l, ' - ', coverageList[l].type, ' - ', zzentplLineCd )
                find_flag = true
                if ( find_flag === true ) {
                  find_flag = false
                  let covAbdaprem = abdacov.covAbdaprem[0] // 보험금 수집
                  let ztpqlCboextAddmAbdacov = abdacov.ztpqlCboextAddmAbdacov // title 수집
                  let aldacov = abdacov.aldacov
                  let covAbdasubjct = abdacov.covAbdasubjct[0] // .zzcontvInsrdCd // : "21"

                  // 202005 외화보험 가입금액
                  // let _CommazaEntAmt = ztpqlCboextAddmAbdacov.zaEntAmt !== 0 ? this.addMoneyCommas(GafMainFunc.setUnitAmt(ztpqlCboextAddmAbdacov.zaEntAmt, coverageList[l].unit)) + coverageList[l].unitNm : ''
                  let _CommazaEntAmt = this.$bizUtil.numberWithCommasCurr(GafMainFunc.setUnitAmt(ztpqlCboextAddmAbdacov.zaEntAmt, coverageList[l].unit), this.currencyId, 'N', 'N')
                  _CommazaEntAmt = this.currencyId === 'KRW' ? _CommazaEntAmt + coverageList[l].unitNm + ' ' : this.currencySymbol + ' ' + _CommazaEntAmt

                  // 202005 외화보험 보험료
                  // let _CommapremaftertaxAm = covAbdaprem.premaftertaxAm !== '' ? this.addMoneyCommas(covAbdaprem.premaftertaxAm) + '원' : ''
                  let _CommapremaftertaxAm = this.$bizUtil.numberWithCommasCurr(covAbdaprem.premaftertaxAm, this.currencyId, 'Y', 'Y', 3)

                  let _Pmprd_1 = GafMainFunc.makePrdText(aldacov.zzinprdTypCd, aldacov.zzinprdTypVl)
                  let _Pmprd_2 = GafMainFunc.makePrdText( covAbdaprem.covAldaprem.zzpmprdTypCd, covAbdaprem.covAldaprem.zzpmprdTypVl )
                  let _Pmprd = _Pmprd_1 + '/' + _Pmprd_2

                  let compareData = {
                    abdacov: abdacov, // 결과 보기에서 사용하기 위한 데이터
                    isChecked: true, // 결과 보기에서 사용하기 위한 데이터
                    isempty: false, // 비교 시 빈항목일 경우
                    zzcontvInsrdCd: abdacov.covAbdasubjct[0].zzcontvInsrdCd, // 피보험자 구분
                    zzcontvInsrdNm: this.fn_covertCodetoName(covAbdasubjct), // 피보험자 명 주피/종피/자녀1,2,3
                    insured: coverageList[l].insured, // 피보험자 코드
                    childNo: coverageList[l].childNo, // 자녀 구분코드
                    order: coverageList[l].order - '0', // 특약 순서
                    uiCode: coverageList[l].uiCode,
                    insCode: coverageList[l].insCode, // T_Model 특약 코드
                    zzcoltrCd: abdacov.zzcoltrCd, // P_Model 특약 코드
                    title: ztpqlCboextAddmAbdacov.zaInsrNm,
                    zaEntAmt: ztpqlCboextAddmAbdacov.zaEntAmt,
                    CommazaEntAmt: _CommazaEntAmt, // 가입금액 - 화면 표시용
                    zaEntAmtGap: '', // 화면에 표시할 데이터
                    zaEntAmtGapVal: 0, // 연산 필요 시에 사용할 데이터
                    PmprdDiffFlag: false,
                    premaftertaxAm: covAbdaprem.premaftertaxAm,
                    CommapremaftertaxAm: _CommapremaftertaxAm, // 특약별 보험료
                    zzmpadCnvlPAmGap: '',
                    zzmpadCnvlPAmGapVal: 0,
                    zzstrkPrmAm: covAbdaprem.covAldaprem.zzstrkPrmAm, // 특약별 보험료( 오른쪽과 왼쪽 차이 계산용 )
                    zzstrkPrmAmGap: '',
                    unit: coverageList[l].unit,
                    unitNm: coverageList[l].unitNm,
                    Pmprd: _Pmprd,
                    Pmprd1: _Pmprd_1,
                    Pmprd2: _Pmprd_2,
                    zzinprdTypCd: aldacov.zzinprdTypCd
                  }
                  this.fn_checkCustomer(compareData, 'rignt')

                  if ( compareData.insured === '21' ) {
                    this.RightEntplEntitys[0].EntplList.push(compareData)
                  }
                  if ( compareData.insured === '23' ) {
                    this.RightEntplEntitys[1].EntplList.push(compareData)
                  }
                  if ( compareData.insured === '24' && compareData.childNo === '1' ) {
                    this.RightEntplEntitys[2].EntplList.push(compareData)
                  }
                  if ( compareData.insured === '24' && compareData.childNo === '2' ) {
                    this.RightEntplEntitys[3].EntplList.push(compareData)
                  }
                  if ( compareData.insured === '24' && compareData.childNo === '3' ) {
                    this.RightEntplEntitys[4].EntplList.push(compareData)
                  }
                }
                break
              }
            }
          }
        }
      }
      for ( let i = 0; i < this.RightEntplEntitys.length; i++ ) {
        this.RightEntplOrgList.push(this.RightEntplEntitys[i])
      }

      this.mergeLeftAndRignt()
    },
    /******************************************************************************
    * Function명 : fn_getHealthValue
    * 설명       : '건강체' 데이터 정보 추출
    ******************************************************************************/
    fn_getHealthValue (baseInfoDto, zzentplHlthsCd) {
      if ( baseInfoDto.gafBaseInfoListDtos ) {
        for ( let i = 0; i < baseInfoDto.gafBaseInfoListDtos.length; i++ ) {
          let el = baseInfoDto.gafBaseInfoListDtos[i]
          if (el.code !== '00003' && el.code !== '00004') {
            continue
          }
          if (!el.list) {
            continue
          }
          let codeArray = el.list.split('|')
          for ( let j = 0; j < codeArray.length; j++) {
            let subel = codeArray[j]
            let keyTextArray = subel.split('_')
            if ( keyTextArray[0] === zzentplHlthsCd ) {
              return keyTextArray[1]
            }
            console.log('keyTextArray', keyTextArray)
          }
        }
      }
    },
    /******************************************************************************
    * Function명 : fn_getpayfrqNm
    * 설명       : '납입 주기' 코드를 텍스트로 변환
    ******************************************************************************/
    fn_getpayfrqNm (baseContDto, baseInfoDto) {
      let zaPadCylCd = baseContDto.zaPadCylCd

      if ( baseInfoDto.gafBaseInfoListDtos ) {
        for ( let i = 0; i < baseInfoDto.gafBaseInfoListDtos.length; i++ ) {
          let el = baseInfoDto.gafBaseInfoListDtos[i]
          if (el.code === '00002') {
            if (!el.list) {
              console.log('Error i need List')
              return ''
            }

            let codeArray = el.list.split('|')
            for ( let j = 0; j < codeArray.length; j++) {
              let subel = codeArray[j]
              let keyTextArray = subel.split('_')
              if ( keyTextArray[0] === zaPadCylCd ) {
                return keyTextArray[1]
              }
              console.log('keyTextArray', keyTextArray)
            }
          }
        }
      }
    },
    /******************************************************************************
    * Function명 : fn_getzzentplLineNm
    * 설명       : '보험 종류' 데이터 정보 추출
    ******************************************************************************/
    fn_getzzentplLineNm (baseContDto, baseInfoDto) {
      let zzentplLinelCd = baseContDto.zzentplLineCd

      if ( baseInfoDto.gafBaseInfoListDtos ) {
        for ( let i = 0; i < baseInfoDto.gafBaseInfoListDtos.length; i++ ) {
          let el = baseInfoDto.gafBaseInfoListDtos[i]
          if (el.code === '00001') {
            if (!el.list) {
              console.log('Error i need List')
              return ''
            }

            let codeArray = el.list.split('|')
            for ( let j = 0; j < codeArray.length; j++) {
              let subel = codeArray[j]
              let keyTextArray = subel.split('_')
              if ( keyTextArray[0] === zzentplLinelCd ) {
                return keyTextArray[1]
              }
              console.log('keyTextArray', keyTextArray)
            }
          }
        }
      }
    },
    /******************************************************************************
    * Function명 : mergeLeftAndRignt
    * 설명       : 비교 설계 정보를 받은 후 동작
    *              PModel의 계약자 별 특약을 하나씩 꺼내서 비교 처리
    *              특약 항목이 없는 경우는 dummyData 정보를 삽입
    ******************************************************************************/
    mergeLeftAndRignt () {
      // 머지를 위해 지역 변수로 데이터 복사
      let _LeftEntplEntitys = []
      let _RightEntplEntitys = []

      let EntplEntity_l = {}
      let EntplEntity_r = {}

      let mainSettingFlag = false // 주보험 설정 시 true
      for ( let count = 0; count < 5; count++ ) {
        EntplEntity_l = {
          custInfo: {},
          EntplList: []
        }
        _LeftEntplEntitys[count] = EntplEntity_l
        EntplEntity_l = {}

        EntplEntity_r = {
          custInfo: {},
          EntplList: []
        }
        _RightEntplEntitys[count] = EntplEntity_r
        EntplEntity_r = {}
      }

      // 설정할 화면 변수의 초기화 처리
      for ( let count = 0; count < 5; count++ ) {
        let EntplEntity_l = { custInfo: {}, EntplList: [] }
        let EntplEntity_r = { custInfo: {}, EntplList: [] }
        this.LeftDiffList.push(EntplEntity_l)
        this.RightDiffList.push(EntplEntity_r)
      }
      // this.LeftEntplEntitys 초기화
      this.LeftEntplEntitys.splice(0, this.LeftEntplEntitys.length)
      this.LeftEntplEntitys.push(this.LeftEntplOrgList[0])

      for ( let mainCnt = 0; mainCnt < 5; mainCnt++ ) {
        // 기준 설계에서 주피, 종피, 자녀 123 중 없는 경우 DummyData로 채움
        if ( ( this.LeftEntplEntitys[mainCnt] === undefined || this.LeftEntplEntitys[mainCnt] === '' ) &&
        ( this.RightEntplEntitys[mainCnt] !== undefined || this.LeftEntplEntitys[mainCnt] !== '' ) ) {
          for ( let idx = 0; idx < this.RightEntplEntitys[mainCnt].length; idx++ ) {
            let dummyData = {
              isempty: true, title: '', Pmprd: '-', Pmprd1: '', Pmprd2: '', CommazaEntAmt: '', CommapremaftertaxAm: '', zaEntAmtGap: '', premaftertaxAm: '', zzstrkPrmAm: ''
            }
            this.LeftEntplEntitys[mainCnt].push(dummyData)
            _LeftEntplEntitys.push(this.LeftEntplEntitys[mainCnt])
          }
          this.LeftDiffList[mainCnt] = this.LeftEntplEntitys[mainCnt]
          this.RightDiffList[mainCnt] = this.RightEntplEntitys[mainCnt]
          continue
        }
        // 비교 설계에서 주피, 종피, 자녀 123 중 없는 경우 DummyData로 채움
        if ( ( this.LeftEntplEntitys[mainCnt] !== undefined || this.LeftEntplEntitys[mainCnt] !== '' ) &&
        ( this.RightEntplEntitys[mainCnt] === undefined || this.LeftEntplEntitys[mainCnt] === '' ) ) {
          for ( let idx = 0; idx < this.LeftEntplEntitys[mainCnt].length; idx++ ) {
            let dummyData = {
              isempty: true, title: '', Pmprd: '-', Pmprd1: '', Pmprd2: '', CommazaEntAmt: '', CommapremaftertaxAm: '', zaEntAmtGap: '', premaftertaxAm: '', zzstrkPrmAm: ''
            }
            this.RightEntplEntitys[mainCnt].push(dummyData)
            _RightEntplEntitys.push(this.RightEntplEntitys[mainCnt])
          }
          this.LeftDiffList[mainCnt] = this.LeftEntplEntitys[mainCnt]
          this.RightDiffList[mainCnt] = this.RightEntplEntitys[mainCnt]
          continue
        }

        let lftCnt = 0
        let l_length = this.LeftEntplEntitys[mainCnt].EntplList.length
        let rigCnt = 0
        let r_length = this.RightEntplEntitys[mainCnt].EntplList.length

        for ( ; lftCnt < l_length || rigCnt < r_length; ) {
          let dummyData = {
            isempty: true, title: '', Pmprd: '-', Pmprd1: '', Pmprd2: '', CommazaEntAmt: '', CommapremaftertaxAm: '', zaEntAmtGap: '', premaftertaxAm: '', zzstrkPrmAm: ''
          }
          // 주보험의 경우 insCode만 같아도 같은 특약으로 처리 ( uiCode가 달라도 같은 특약으로 처리 )
          // 20190409 주보험의 경우 조건 없이 첫번째 항목으로 설정하여 비교 처리
          if ( mainSettingFlag === false ) { // && ( this.LeftEntplEntitys[0].EntplList[0].insCode === this.RightEntplEntitys[0].EntplList[0].insCode ) ) {
            _LeftEntplEntitys[0].EntplList.push( this.LeftEntplEntitys[0].EntplList[0] )
            _RightEntplEntitys[0].EntplList.push( this.RightEntplEntitys[0].EntplList[0] )
            this.calDifferentVal(this.LeftEntplEntitys[0].EntplList[0], this.RightEntplEntitys[0].EntplList[0], this.LeftEntplEntitys[0].EntplList[0].unit, this.LeftEntplEntitys[0].EntplList[0].unitNm)
            lftCnt++
            rigCnt++

            if ( this.RightEntplEntitys[0].EntplList[0].zaEntAmtGap !== '' || // 가입 금액이 다를 때
             this.RightEntplEntitys[0].EntplList[0].zzmpadCnvlPAmGap !== '' || // 보험료 금액이 다를 때
             this.RightEntplEntitys[0].EntplList[0].PmprdDiffFlag === true ) { // 보기/납기가 다를 때)
              this.LeftDiffList[0].EntplList.push( this.LeftEntplEntitys[0].EntplList[0] )
              this.RightDiffList[0].EntplList.push( this.RightEntplEntitys[0].EntplList[0] )
            }
            mainSettingFlag = true
          }

          // 기준설계 / 비교 설계 차이점 비교 처리
          // 1. 두 설계에 동일 특약이 있는 경우
          if ( this.LeftEntplEntitys[mainCnt].EntplList[lftCnt] !== undefined && this.RightEntplEntitys[mainCnt].EntplList[rigCnt] !== undefined ) {
            if ( ( this.LeftEntplEntitys[mainCnt].EntplList[lftCnt].insCode === this.RightEntplEntitys[mainCnt].EntplList[rigCnt].insCode ) &&
                 ( this.LeftEntplEntitys[mainCnt].EntplList[lftCnt].uiCode === this.RightEntplEntitys[mainCnt].EntplList[rigCnt].uiCode ) &&
                ( this.LeftEntplEntitys[mainCnt].EntplList[lftCnt].type === this.RightEntplEntitys[mainCnt].EntplList[rigCnt].type ) ) {
              this.calDifferentVal(this.LeftEntplEntitys[mainCnt].EntplList[lftCnt], this.RightEntplEntitys[mainCnt].EntplList[rigCnt], this.LeftEntplEntitys[mainCnt].EntplList[lftCnt].unit, this.LeftEntplEntitys[mainCnt].EntplList[lftCnt].unitNm)
              _LeftEntplEntitys[mainCnt].EntplList.push( this.LeftEntplEntitys[mainCnt].EntplList[lftCnt] )
              _RightEntplEntitys[mainCnt].EntplList.push( this.RightEntplEntitys[mainCnt].EntplList[rigCnt] )

              if ( this.RightEntplEntitys[mainCnt].EntplList[rigCnt].zaEntAmtGap !== '' || // 가입 금액이 다를 때
              this.RightEntplEntitys[mainCnt].EntplList[rigCnt].zzmpadCnvlPAmGap !== '' || // 보험료 금액이 다를 때
              this.RightEntplEntitys[mainCnt].EntplList[rigCnt].PmprdDiffFlag === true) { // 보기/납기가 다를 때
                this.LeftDiffList[mainCnt].EntplList.push( this.LeftEntplEntitys[mainCnt].EntplList[lftCnt] )
                this.RightDiffList[mainCnt].EntplList.push( this.RightEntplEntitys[mainCnt].EntplList[rigCnt] )
              }

              lftCnt++
              rigCnt++
            } else { // 2. 기준 / 비교 설계에 특약이 없는 경우
              // 2.1 비교 설계에 특약이 없는 경우
              if ( this.LeftEntplEntitys[mainCnt].EntplList[lftCnt].order < this.RightEntplEntitys[mainCnt].EntplList[rigCnt].order ) {
                _LeftEntplEntitys[mainCnt].EntplList.push( this.LeftEntplEntitys[mainCnt].EntplList[lftCnt] )
                _RightEntplEntitys[mainCnt].EntplList.push( dummyData )

                this.LeftDiffList[mainCnt].EntplList.push(this.LeftEntplEntitys[mainCnt].EntplList[lftCnt])
                this.RightDiffList[mainCnt].EntplList.push(dummyData)

                lftCnt++
              } else if ( this.LeftEntplEntitys[mainCnt].EntplList[lftCnt].order > this.RightEntplEntitys[mainCnt].EntplList[rigCnt].order ) { // 2.2 기준 설계에 특약이 없는 경우
                dummyData.title = this.RightEntplEntitys[mainCnt].EntplList[rigCnt].title
                _LeftEntplEntitys[mainCnt].EntplList.push( dummyData )
                _RightEntplEntitys[mainCnt].EntplList.push( this.RightEntplEntitys[mainCnt].EntplList[rigCnt] )

                this.LeftDiffList[mainCnt].EntplList.push( dummyData )
                this.RightDiffList[mainCnt].EntplList.push( this.RightEntplEntitys[mainCnt].EntplList[rigCnt] )

                rigCnt++
              } else if ( this.LeftEntplEntitys[mainCnt].EntplList[lftCnt].order === this.RightEntplEntitys[mainCnt].EntplList[rigCnt].order ) { // 2.3 특약 order 값이 같으나 다른 특약 경우 처리. 각각 따로 입력
                _LeftEntplEntitys[mainCnt].EntplList.push( this.LeftEntplEntitys[mainCnt].EntplList[lftCnt] )
                _RightEntplEntitys[mainCnt].EntplList.push( dummyData )

                this.LeftDiffList[mainCnt].EntplList.push( this.LeftEntplEntitys[mainCnt].EntplList[lftCnt] )
                this.RightDiffList[mainCnt].EntplList.push( dummyData )

                dummyData.title = this.RightEntplEntitys[mainCnt].EntplList[rigCnt].title
                _LeftEntplEntitys[mainCnt].EntplList.push( dummyData )
                _RightEntplEntitys[mainCnt].EntplList.push( this.RightEntplEntitys[mainCnt].EntplList[rigCnt] )

                this.LeftDiffList[mainCnt].EntplList.push( dummyData )
                this.RightDiffList[mainCnt].EntplList.push( this.RightEntplEntitys[mainCnt].EntplList[rigCnt] )

                lftCnt++
                rigCnt++
              }
            }
          } else {
            // 기준 설계 특약 목록 다 처리한 상태 : 비교 설계의 남은 특약 처리 ( 비교 설계에 dummyData로 채움 )
            if ( rigCnt < r_length && lftCnt === l_length ) {
              // console.log('lftCnt < l_length ///////////////// OK-OK-OK-OK-')
              let dummyData = {
                isempty: true, title: '', Pmprd: '-', Pmprd1: '', Pmprd2: '', CommazaEntAmt: '', CommapremaftertaxAm: '', zaEntAmtGap: '', premaftertaxAm: '', zzstrkPrmAm: ''
              }
              dummyData.title = this.RightEntplEntitys[mainCnt].EntplList[rigCnt].title
              _LeftEntplEntitys[mainCnt].EntplList.push( dummyData )
              _RightEntplEntitys[mainCnt].EntplList.push( this.RightEntplEntitys[mainCnt].EntplList[rigCnt] )

              this.LeftDiffList[mainCnt].EntplList.push( dummyData )
              this.RightDiffList[mainCnt].EntplList.push( this.RightEntplEntitys[mainCnt].EntplList[rigCnt] )
              rigCnt++
            }

            // 비교 설계 특약 목록 다 처리한 상태 : 기준 설계의 남은 특약 처리 ( 비교 설계에 dummyData로 채움 )
            if ( lftCnt < l_length && rigCnt === r_length ) {
              // console.log('rigCnt < r_length ///////////////// OK-OK-OK-OK-')
              let dummyData = {
                isempty: true, title: '', Pmprd: '-', Pmprd1: '', Pmprd2: '', CommazaEntAmt: '', CommapremaftertaxAm: '', zaEntAmtGap: '', premaftertaxAm: '', zzstrkPrmAm: ''
              }
              _LeftEntplEntitys[mainCnt].EntplList.push( this.LeftEntplEntitys[mainCnt].EntplList[lftCnt] )
              _RightEntplEntitys[mainCnt].EntplList.push( dummyData )

              this.LeftDiffList[mainCnt].EntplList.push( this.LeftEntplEntitys[mainCnt].EntplList[lftCnt] )
              this.RightDiffList[mainCnt].EntplList.push( dummyData )
              lftCnt++
            }
          }
        }
      }
      // 화면 처리를 위한 변수를 초기화
      this.LeftEntplEntitys.splice(0, this.LeftEntplEntitys.length)
      this.RightEntplEntitys.splice(0, this.RightEntplEntitys.length)

      // 설계 보기 / 차이점 모아보기 용 초기화 - 차이점 모아보기 정보(LeftDiffList, RightDiffList)는 위에서 데이터 셋팅
      this.LeftAllList.splice(0, this.LeftAllList.length)
      this.RightAllList.splice(0, this.RightAllList.length)

      // 화면 처리 변수에 값을 셋팅
      for ( let i = 0; i < _LeftEntplEntitys.length; i++ ) {
        this.LeftEntplEntitys.push(_LeftEntplEntitys[i])
        this.RightEntplEntitys.push(_RightEntplEntitys[i])

        this.LeftAllList.push(_LeftEntplEntitys[i])
        this.RightAllList.push(_RightEntplEntitys[i])
      }

      // 특약 목록 합본 구성 - 주피보험자만 리스트에 담는다
      this.allEntplOrgList.LeftEntplList.splice(0, this.allEntplOrgList.LeftEntplList.length)  // 특약목록 기준 전체 리스트
      this.allEntplOrgList.RightEntplList.splice(0, this.allEntplOrgList.RightEntplList.length)  // 특약목록 비교 전체 리스트
      this.allEntplViewList.splice(0, this.allEntplViewList.length) // 차이보기 비선택시 보여지는 특약 리스트
      this.allEntplDiffList.splice(0, this.allEntplDiffList.length) // 차이보기 선택시 보여지는 특약 리스트
      this.allEntplCurrList.splice(0, this.allEntplCurrList.length) // 현재 화면에 보여지는 특약 리스트
      for (let i=0 ; i < _LeftEntplEntitys[0].EntplList.length ; i++) {
        this.allEntplOrgList.LeftEntplList.push(_LeftEntplEntitys[0].EntplList[i])
        this.allEntplOrgList.RightEntplList.push(_RightEntplEntitys[0].EntplList[i])
        // 차이보기 미선택시 보여지는 리스트 구성
        let tmpObj = {}
        tmpObj = _LeftEntplEntitys[0].EntplList[i]
        tmpObj._CommapremaftertaxAm = _RightEntplEntitys[0].EntplList[i].CommapremaftertaxAm
        tmpObj._CommazaEntAmt = _RightEntplEntitys[0].EntplList[i].CommazaEntAmt
        tmpObj._Pmprd1 = _RightEntplEntitys[0].EntplList[i].Pmprd1
        tmpObj._Pmprd2 = _RightEntplEntitys[0].EntplList[i].Pmprd2
        tmpObj._PmprdDiffFlag = _RightEntplEntitys[0].EntplList[i].PmprdDiffFlag
        tmpObj._zaEntAmtGap = _RightEntplEntitys[0].EntplList[i].zaEntAmtGap
        tmpObj._zaEntAmtGapVal = _RightEntplEntitys[0].EntplList[i].zaEntAmtGapVal
        tmpObj._zzmpadCnvlPAmGap = _RightEntplEntitys[0].EntplList[i].zzmpadCnvlPAmGap
        tmpObj._zzmpadCnvlPAmGapVal = _RightEntplEntitys[0].EntplList[i].zzmpadCnvlPAmGapVal
        this.allEntplViewList.push(tmpObj)
        // 차이보기 선택시 보여지는 리스트 구성
        if(_RightEntplEntitys[0].EntplList[i].zaEntAmtGap !== '' || // 가입 금액이 다를 때
            _RightEntplEntitys[0].EntplList[i].zzmpadCnvlPAmGap !== '' || // 보험료 금액이 다를 때
            _RightEntplEntitys[0].EntplList[i].PmprdDiffFlag === true ||
            _LeftEntplEntitys[0].EntplList[i].Pmprd1 === '') { // 보기/납기가 다를 때
          this.allEntplDiffList.push(tmpObj)
        }
      }
      this.allEntplCurrList = this.allEntplViewList.slice(0) // 현재 화면 리스트 구성
      this.compareModeDisabled = false // 차이보기 활성화
      this.compareModeFlag = false // 차이보기 값 초기화

      this.isCompare = true
      this.fn_getBtnCtrlInfo()
    },
    /******************************************************************************
    * Function명 : calDifferentVal
    * 설명       : 기준 설계/비교 설계 특약 정보로 금액 차이에 따라 아이콘 및 금액 연산
    *              202005 외화보험 소수점자리표시용 useDeci 추가
    ******************************************************************************/
    gapValueProcess ( gap, unit, unitNm, useDeci) {
      let _tempGap = ''
      let sign = ''
      let result = ''

      if ( gap > 0 ) {
        _tempGap = gap
        sign = '↑'
      } else if ( gap < 0 ) {
        _tempGap = gap * -1
        sign = '↓'
      }

      /* 202005 외화보험 통화키 수정전
      if ( unit !== '' ) {
        result = gap !== 0 ? sign + this.addMoneyCommas(GafMainFunc.setUnitAmt(_tempGap, unit)) + unitNm : ''
      } else {
        result = gap !== 0 ? sign + this.addMoneyCommas( _tempGap ) + '원' : ''
      }
      */

      // 202005 외화보험 통화키
      if (gap !== 0) {
        result = unit !== '' ? GafMainFunc.setUnitAmt(_tempGap, unit) : _tempGap
        result = this.$bizUtil.numberWithCommasCurr(result, this.currencyId, useDeci, 'N')
        if (this.currencyId === 'KRW') {
          result = unit !== '' ? sign + result + unitNm : sign + result + '원'
        } else if (this.currencyId === 'USD') {
          result = sign + '$' + result
        }
      }

      return result
    },
    /******************************************************************************
    * Function명 : calDifferentVal
    * 설명       : 비교 설계에 차이점 표시하기 위한 연산 수행
    *              기준 설계/비교 설계 특약 정보로 금액 차이 계산 처리
    ******************************************************************************/
    calDifferentVal (LeftItem, RightItem, unit, unitNm) {
      // 차이 연산의 기준은 비교설계를 기준으로 처리
      let _zaEntAmtGap = RightItem.zaEntAmt - LeftItem.zaEntAmt // this.addMoneyCommas()
      let _zzmpadCnvlPAmGap = RightItem.premaftertaxAm - LeftItem.premaftertaxAm // 보험료 차이 계산
      let _PmprdDiffFlag = !(RightItem.Pmprd === LeftItem.Pmprd)

      RightItem.zaEntAmtGapVal = _zaEntAmtGap
      RightItem.zzmpadCnvlPAmGapVal = _zzmpadCnvlPAmGap
      RightItem.PmprdDiffFlag = _PmprdDiffFlag

      // 202005 외화보험 소숫점 표시추가
      // RightItem.zaEntAmtGap = this.gapValueProcess(_zaEntAmtGap, unit, unitNm) // _zaEntAmtGap !== 0 ? this.addMoneyCommas(GafMainFunc.setUnitAmt(_zaEntAmtGap, unit)) + unitNm : ''
      // RightItem.zzmpadCnvlPAmGap = this.gapValueProcess(_zzmpadCnvlPAmGap, '', '') // _zzmpadCnvlPAmGap !== 0 ? this.addMoneyCommas(_zzmpadCnvlPAmGap) + '원' : ''
      RightItem.zaEntAmtGap = this.gapValueProcess(_zaEntAmtGap, unit, unitNm, 'N') // 가입금액
      RightItem.zzmpadCnvlPAmGap = this.gapValueProcess(_zzmpadCnvlPAmGap, '', '', 'Y') // 보험료
    },
    /******************************************************************************
    * Function명 : fn_checkCustomer
    * 설명       : 피보험자 존재 유무 체크
    *              모든 특약을 검색하면서, 특약에 등록 안된 계약자 있을 경우 목록에 등록
    *              등록된 계약자 Flag로 처리
    ******************************************************************************/
    fn_checkCustomer ( compareData, pannel ) {
      let rltnrInfo = ''
      if ( pannel === 'left' ) {
        rltnrInfo = this.leftResult.tmodel.rltnrInfo
      } else {
        rltnrInfo = this.rightResult.tmodel.rltnrInfo
      }
      if ( compareData.zzcontvInsrdCd === '21' && this.isThere21 === false ) {
        this.isThere21 = true
        let _rltnrInfo_21 = rltnrInfo.filter((item) => { return item.zzcontvInsrdCd === '21' })[0]
        let custInfo = {
          type: this.fn_covertCodetoName(compareData),
          code: compareData.zzcontvInsrdCd,
          name: _rltnrInfo_21.zaInsrdNm
        }
        this.LeftEntplEntitys[0].custInfo = custInfo
        this.CustomerList[0] = custInfo
      }
      if ( compareData.zzcontvInsrdCd === '23' && this.isThere23 === false ) {
        this.isThere23 = true
        let _rltnrInfo_23 = rltnrInfo.filter((item) => { return item.zzcontvInsrdCd === '23' })[0]
        let custInfo = {
          type: this.fn_covertCodetoName(compareData),
          code: compareData.zzcontvInsrdCd,
          name: _rltnrInfo_23.zaInsrdNm
        }
        this.CustomerList[1] = custInfo
      }
      if ( compareData.zzcontvInsrdCd === '24' && compareData.childNo === '1' && this.isThere24_1 === false ) {
        this.isThere24_1 = true
        let _rltnrInfo_24_1 = rltnrInfo.filter((item) => { return item.zzcontvInsrdCd === '24' && item.zzchldSnoVl === 1 })[0]
        let custInfo = {
          type: this.fn_covertCodetoName(compareData),
          code: compareData.zzcontvInsrdCd,
          name: _rltnrInfo_24_1.zaInsrdNm
        }
        this.LeftEntplEntitys[2].custInfo = custInfo
        this.CustomerList[2] = custInfo
      }
      if ( compareData.zzcontvInsrdCd === '24' && compareData.childNo === '2' && this.isThere24_2 === false ) {
        this.isThere24_2 = true
        let _rltnrInfo_24_2 = rltnrInfo.filter((item) => { return item.zzcontvInsrdCd === '24' && item.zzchldSnoVl === 2 })[0]
        let custInfo = {
          type: this.fn_covertCodetoName(compareData),
          code: compareData.zzcontvInsrdCd,
          name: _rltnrInfo_24_2.zaInsrdNm
        }
        this.LeftEntplEntitys[3].custInfo = custInfo
        this.CustomerList[3] = custInfo
      }
      if ( compareData.zzcontvInsrdCd === '24' && compareData.childNo === '3' && this.isThere24_3 === false ) {
        this.isThere24_3 = true
        let _rltnrInfo_24_3 = rltnrInfo.filter((item) => { return item.zzcontvInsrdCd === '24' && item.zzchldSnoVl === 3 })[0]
        let custInfo = {
          type: this.fn_covertCodetoName(compareData),
          code: compareData.zzcontvInsrdCd,
          name: _rltnrInfo_24_3.zaInsrdNm
        }
        this.LeftEntplEntitys[4].custInfo = custInfo
        this.CustomerList[4] = custInfo
      }
    },
    /******************************************************************************
    * Function명 : fn_covertCodetoName
    * 설명       : 피보험자 종류 별 Text 설정
    ******************************************************************************/
    fn_covertCodetoName (covAbdasubjct) {
      let zzcontvInsrdNm = ''

      if (covAbdasubjct.zzcontvInsrdCd === '21') {
        zzcontvInsrdNm = '주피'
      } else if (covAbdasubjct.zzcontvInsrdCd === '23') {
        zzcontvInsrdNm = '종피'
      } else if (covAbdasubjct.zzcontvInsrdCd === '24' && covAbdasubjct.childNo === '1') {
        zzcontvInsrdNm = '자녀1'
      } else if (covAbdasubjct.zzcontvInsrdCd === '24' && covAbdasubjct.childNo === '2') {
        zzcontvInsrdNm = '자녀2'
      } else if (covAbdasubjct.zzcontvInsrdCd === '24' && covAbdasubjct.childNo === '3') {
        zzcontvInsrdNm = '자녀3'
      } // end else if

      // 2017.09.05 대상자 계약자로 셋팅
      if (covAbdasubjct.zaUndwItmCd === 'IAML001' ||
              covAbdasubjct.zaUndwItmCd === 'IAML002' ||
              covAbdasubjct.zaUndwItmCd === 'IAML003') {
        zzcontvInsrdNm = '계약자'
      }
      return zzcontvInsrdNm
    },
    /******************************************************************************
    * Function명 : requestEtrlList
    * 설명       : 최근가입설계 목록 조회 요청 함수
    ******************************************************************************/
    requestEtrlList () {
      let headerVO = this.getStore('userInfo').getters.getUserInfo
      let rltnrInfo = this.leftResult.tmodel.rltnrInfo
      let _rltnrInfo_21 = {}
      let _rltnrInfo_11 = {}
      for ( let i = 0; i < rltnrInfo.length; i++ ) {
        // 피보험자
        if ( rltnrInfo[i].zzcontvInsrdCd === '21' ) {
          _rltnrInfo_21 = rltnrInfo[i]
        }
        // 계약자
        if ( rltnrInfo[i].zzcontvInsrdCd === '11' ) {
          _rltnrInfo_11 = rltnrInfo[i]
        }
      }

      let inputJson = {
        clctCnsltId: headerVO.userEno, // 설계사 번호
        chnlCustId: _rltnrInfo_21.zaAgtFileId, // 피보험자 ID
        contrChnlCustId: _rltnrInfo_11.zaAgtFileId, // 계약자 ID
        productCd: this.leftResult.tmodel.prodInfo.searchCriteria.productId,
        eusPrgStatCd: '3', // AEUS입력여부조회조건이 전체(3)
        planScCd: '00' // 검색조건 : 전체(청약발행이전)
      }
      // GA 매니저 대행 컨설턴트 변경시
      if(this.$cmUtil.fn_GachangeTF()){
        inputJson.clctCnsltId = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
      }

      let pagingJson = {}
      pagingJson.zaFnctScCd = 'S'// (1)조회구분 ※S:최초 조회, N:다음 조회
      pagingJson.zaPageRowCnt = 100// (2)조회 단위(30,50,100)

      inputJson.zPagingDto = pagingJson
      let reqVal = JSON.stringify(inputJson)
      reqVal = reqVal.replace(/\\/gi, '')

      let pParams = {
        cmndSrvcNm: 'selProcessListPlan',
        sdrvDtVal: reqVal
      }
      piCommonUtil.invoke('URLPQL00133', pParams, this.fn_searchCallBack, null, this.fn_searchCallBack, this, this)
    },
    /******************************************************************************
    * Function명 : fn_searchCallBack
    * 설명       : 최근가입설계 목록 조회 CallBack
    *              cardAllList 정보에 대한 처리
    ******************************************************************************/
    fn_searchCallBack (result) {
      if (result === null || result === undefined || !piCommonUtil.isObj(result.sdrvDtVal)) {
        return false
      } else {
        result = JSON.parse(result.sdrvDtVal)
        this.cardList.splice(0, this.cardList.length)
        this.cardAllList.splice(0, this.cardAllList.length)
        // data 영역에 수신데이터 담기
        this.cardAllList = result.zPlanProcessStatusViewListDto

        // 반복문을 돌며, 수신데이터의 날짜를 앞에서부터 8자만 잘라서 새로이 데이터 구성.
        for (let i = 0; i < this.cardAllList.length; i++) {
          this.cardAllList[i].entplYmd = this.cardAllList[i].entplYmd.substring(0, 8)
          this.cardAllList[i].title = this.fn_getPlanName(this.TrtyName, this.cardAllList[i].planNm) // .split('_')[1]
          this.cardAllList[i].date = this.dateFormat(this.cardAllList[i].entplYmd)

          // 202005 외화보험
          // this.cardAllList[i].prm = this.addMoneyCommas(this.cardAllList[i].smtotPrm)
          this.cardAllList[i].prm = this.$bizUtil.numberWithCommasCurr(this.cardAllList[i].smtotPrm, this.currencyId, 'Y', 'N')

          if (this.cardAllList[i].planId === this.LeftEntplEntitys.zaPlanId) {
            continue
          }
          this.cardAllList[i].chk = false
          this.cardList.push(this.cardAllList[i])
        }
      }
    },
    /******************************************************************************
    * Function명 : dateFormat
    * 설명       : 날짜 포멧 처리
    ******************************************************************************/
    dateFormat (val) {
      return piCommonUtil.dateFormat(val)
    },
    /******************************************************************************
    * Function명 : addMoneyCommas
    * 설명       : 금액의 1000단위 , 처리
    ******************************************************************************/
    addMoneyCommas (val) {
      return piCommonUtil.addMoneyCommas(val)
    },
    /******************************************************************************
    * Function명 : addCompare
    * 설명       : 최근가입설계 목록에서 기준/비교 설계 건 선택 시 처리
    ******************************************************************************/
    addCompare (item) {
      // 기선택된건은 처리 안함
      if (item.chk) {
        this.fn_CloseAddPlan()
        return
      }
      // 일단 해당영역을 클릭하면 설계 오픈되도록
      if (this.isInit) {
        this.isInit = false
        this.requestPanel = 'left'
        this.fn_requestPlanByPlanId(item.planId)
      } else {
        this.requestPanel = 'right'
        this.fn_requestPlanByPlanId(item.planId)
      }

      this.compareModeFlag = false
      let tmpArr = []
      for (let i = 0; i < this.cardList.length; i++) {
        if (item.planId === this.cardList[i].planId) {
          this.cardList[i].chk = true
        } else {
          this.cardList[i].chk = false
        }
        tmpArr.push(this.cardList[i])
      }
      this.cardList.splice(0, this.cardList.length)
      this.cardList = tmpArr

      this.fn_CloseAddPlan()
    },
    /****************************************************************************************************
    * 함수 : fn_getBtnCtrlInfo()
    * 설명 : 연령재계산 및 보험료 계산전 단계로 버튼 및 안내 메세지 출력
    /***************************************************************************************************/
    fn_getBtnCtrlInfo () {
      console.log('### 알림', '연령재계산 및 보험료 계산전 단계로 버튼 및 안내 메세지 출력')
      let rltnrInfo = this.rightResult.tmodel.rltnrInfo
      let abdacovpac = this.rightResult.pmodel.abdapolicy[0].abdapolpr[0].abdacovpac
      let _rltnrInfo
      let _rltnrInfo23
      let _zzcontvInsrdCd
      let _zzchldSnoVl
      let _ageatentryVl
      let _msgs = []
      let isExist = false

      for ( let i = 0; i < abdacovpac.length; i++ ) {
        let el = abdacovpac[i]

        _zzcontvInsrdCd = piCommonUtil.isEmpty2(el.abdacov[0].covAbdasubjct[0].zzcontvInsrdCd) ? '21' : el.abdacov[0].covAbdasubjct[0].zzcontvInsrdCd
        if ( el.abdacov[0].covAbdasubjct[0].zzcontvInsrdCd === '21' ) {
          _zzchldSnoVl = _zzcontvInsrdCd === '21' ? '0' : el.abdacov[0].covAbdasubjct[0].zzchldSnoVl
          if (piCommonUtil.isEmpty2(_zzchldSnoVl)) {
            _zzchldSnoVl = '0'
          }
        }
        _rltnrInfo = rltnrInfo.filter((item) => {
          return piCommonUtil.NumCal(item.zzcontvInsrdCd, _zzcontvInsrdCd, '-') === 0 &&
                                                                             // GafRltnrInfoDto의 zzchldSnoVl는 int 타입이라서 값이 없으면 0으로 옴.
                                                                             // GafAbdacovpacDto의 zzchldSnoVl는 BigDecimal 이라서, 값이 없으면 undefined 임.
                                                                             piCommonUtil.NumCal(item.zzchldSnoVl, _zzchldSnoVl, '-') === 0
        })

        if (piCommonUtil.isObj(el.abdacov[0].covAbdasubjct[0].ageatentryVl) && piCommonUtil.isObj(_rltnrInfo) && _rltnrInfo.length > 0) {
          if (piCommonUtil.NumCal(el.abdacov[0].covAbdasubjct[0].ageatentryVl, _rltnrInfo[0].ageatentryVl, '-') !== 0) {
            // PQLALT00510 : {0}고객은 설계시점의 연령과 현재 연령이 다릅니다. \n보험료계산을 다시 하셔야 합니다.
            // console.log('### 알림', 'msgs.push', '고객은 설계시점의 연령과 현재 연령이 다릅니다. \n보험료계산을 다시 하셔야 합니다.' )
            // _msgs.push([_rltnrInfo[0]._custNm] + '고객은 설계시점의 연령과 현재 연령이 다릅니다. \n보험료계산을 다시 하셔야 합니다.')
            _msgs.push('설계시점과 현재의 고객 나이가 다릅니다.\n보험료를 다시 계산해주세요.')
          }
        }

        // 직업코드 상이 체크추가
        if (piCommonUtil.isObj(el.abdacov[0].covAbdasubjct[0].zzinsrEnJobCd) && piCommonUtil.isObj(_rltnrInfo) && _rltnrInfo.length > 0) {
          if (el.abdacov[0].covAbdasubjct[0].zzinsrEnJobCd !== _rltnrInfo[0].zzinsrJobCd) {
            // PQLALT00515 : {0}고객은 설계시점의 직업과 현재 직업이 다릅니다. \n보험료계산을 다시 하셔야 합니다.
            console.log('### 알림', 'msgs.push', '고객은 설계시점의 직업과 현재 직업이 다릅니다. \n보험료계산을 다시 하셔야 합니다.' )
            _msgs.push([_rltnrInfo[0]._custNm] + '고객은 설계시점의 직업과 현재 직업이 다릅니다. \n보험료계산을 다시 하셔야 합니다.')
          } else {
            if (piCommonUtil.isObj(_rltnrInfo['0'].zzinjryRskGCd) && (piCommonUtil.isObj(el.abdacov['0'].covAbdasubjct['0'].zzinjryRskGCd) && el.abdacov['0'].covAbdasubjct['0'].zzinjryRskGCd !== '')) {
              // 201902 직업코드 같고 상해위험등급 변경되었을때 처리함
              if (el.abdacov['0'].covAbdasubjct['0'].zzinjryRskGCd !== _rltnrInfo['0'].zzinjryRskGCd) {
                console.log('### 알림', 'msgs.push', '고객은 설계시점과 현재시점의 직업 및 상해위험등급이 다릅니다. 보험료계산을 다시 하셔야 합니다.' )
                _msgs.push([_rltnrInfo['0']._custNm] + '고객은 설계시점과 현재시점의 직업 및 상해위험등급이 다릅니다. 보험료계산을 다시 하셔야 합니다.')
              }
            }
          }
        }

        // 연생보험체크:subject가 2개이상이고 보험관점. 계약관점피보험자가 23인 case를 연생보험으로 체크한다.
        for (let k in el.abdacov) {
          if (el.abdacov[k].covAbdasubjct.length > 1) {
            for (let j in el.abdacov[k].covAbdasubjct) {
              if (el.abdacov[k].covAbdasubjct[j].zzcontvInsrdCd === '23' && el.abdacov[k].covAbdasubjct[j].zzinsuvInsrdCd === '23') {
                _ageatentryVl = el.abdacov[k].covAbdasubjct[j].ageatentryVl
                isExist = true
                break
              }
            }
          }
        }
      }

      if (isExist) {
        _rltnrInfo23 = rltnrInfo.filter((item) => { return item.zzcontvInsrdCd === '23' })
        if (piCommonUtil.NumCal(_ageatentryVl, _rltnrInfo23[0].ageatentryVl, '-') !== 0) {
          // PQLALT00510 : {0}고객은 설계시점의 연령과 현재 연령이 다릅니다. \n보험료계산을 다시 하셔야 합니다.
          // console.log('### 알림', 'msgs.push', '고객은 설계시점의 연령과 현재 연령이 다릅니다. \n보험료계산을 다시 하셔야 합니다.' )
          // _msgs.push([_rltnrInfo[0]._custNm] + '고객은 설계시점의 연령과 현재 연령이 다릅니다. \n보험료계산을 다시 하셔야 합니다.')
          _msgs.push('설계시점과 현재의 고객 나이가 다릅니다.\n보험료를 다시 계산해주세요.')
        }
      }

      console.log('### 알림', '_msgs.length', _msgs.length )

      if (_msgs.length > 0) {
        let _message = _msgs.join('\n')
        piCommonUtil.getFDPConfirm('s', _message)
      }
    },

    //코딩 종료 함수 마지막 메서드 함수 , 없음 주의
    _fn_End() {return}
  }
}
</script>